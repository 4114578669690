<template>
  <base-route-card :route="route">
    <template v-slot:main>
      <price :pricing="route.pricing" />
    </template>
    <template v-slot:footer> 
      <div
        class="text-neutral-lighter flex items-center justify-between gap-5 max-md:flex-col"
      >
      
        <div class="flex flex-col gap-2">
  <h3 style="font-weight:bold" v-if="route.pricing.demarche_apmb == 0">OPTION</h3> 
 <div class="flex max-md:mb-5" v-if="route.pricing.demarche_apmb == 0">


      <input
  type="checkbox"
  v-model="forfaitChecked"
  :disabled="loading"
  id="forfait_demarche_mb" 
style="margin-right: 5px;"
/>
  
    <label for="forfait_demarche_mb" class="text-primary cursor-pointer">
    FORFAIT DEMARCHE
            <span class="font-bold">{{
              $n(route.pricing.demarche_mb, "currency")
            }}
            TTC soit {{ $n(route.pricing.demarche_mbwithouttax, "currency") }} HT
             </span> 


    </label>
        </div>
        

         <div class="flex max-md:mb-5" v-if="route.pricing.demarche_apmb != 0">


  
  
    <label for="forfait_demarche_mb" class="text-primary cursor-pointer">
   <!--  FORFAIT DEMARCHE
            <span class="font-bold">{{
              $n(route.pricing.demarche_mb, "currency")
            }}
            TTC soit {{ $n(route.pricing.demarche_mbwithouttax, "currency") }} HT
             </span> -->
             Cet opérateur ne souhaite pas faire de démarches

    </label>
        </div>

          <div class="flex max-md:mb-5">

            <icon icon="lit" class="text-primary-light mr-3 h-6 w-6"></icon>
            <span v-if="route.vehicle.type === 'before_coffin'">
              Transport avant et après mise en bière</span
            >
            <span v-if="route.vehicle.type === 'after_coffin'">
              Transport après mise en bière</span
            >
          </div>
          <div class="flex max-md:mb-5">
            <icon
              icon="dimensions"
              class="text-primary-light mr-3 h-6 w-6"
            ></icon>
            <span v-if="route.vehicle.is_standard_size">
              Supporte des tailles standards uniquement (&lt ou = à 195cm x
              60cm)</span
            >
            <span v-else>
              Supporte des tailles hors normes (> à 195cm x l60cm)</span
            >
          </div>
        </div>

        <div v-if="!hideBooking">
          <rounded-button
            v-if="route.is_reservation"
            class="bg-secondary max-md:px-24"
            disabled
            design="4"
          >
            EN ATTENTE DE VALIDATION
          </rounded-button>
          <rounded-button
            disabled
            v-if="route.is_mine"
            class="max-md:px-24"
            design="4"
          >
            VOTRE TRAJET
          </rounded-button>
          <rounded-button
            v-if="!route.is_reservation && !route.is_mine"
            class="max-md:px-24"
            design="3"
            @click="$emit('book', route)"
          >
            RESERVER
          </rounded-button>
        </div>
      </div>
    </template>
  </base-route-card>
</template>

<script setup>
import { defineProps } from "vue";
const { $api } = useNuxtApp();
const router = useRouter();
const props = defineProps({
  route: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  hideBooking: {
    type: Boolean,
    default: false,
  },
});

const forfaitChecked = ref(false);

const adjustPricing = () => {
  if (forfaitChecked.value) {
    // If checked, add demarche_mb and demarche_mbwithouttax to total and totalht
    console.log(props.route.pricing,'yasirshah 22');
    props.route.pricing.total_ttc += props.route.pricing.demarche_mb;
    props.route.pricing.total_ht += props.route.pricing.demarche_mbwithouttax;
  } else {
    // If unchecked, subtract demarche_mb and demarche_mbwithouttax from total and totalht
       
    props.route.pricing.total_ttc -= props.route.pricing.demarche_mb;
    props.route.pricing.total_ht -= props.route.pricing.demarche_mbwithouttax;
     console.log(props.route.pricing,'yasirshah3553');
  }
};

// Watch for changes in the checkbox state
watch(forfaitChecked, () => {
  adjustPricing();
});

// Perform adjustment on page load based on the initial state of forfaitChecked


onMounted(async () => {
  const isCheckboxVisible = ref(true);
  await nextTick(); // Ensure the DOM is updated
  const dialogElement = document.querySelector('.p-dialog.p-component');
 const checkboxInput = document.querySelector('#forfait_demarche_mb');

  if (!dialogElement) {
//checkboxInput.style.display = 'block'; 
  //  checkboxInput.disabled = false; 
  
   // adjustPricing();  // Apply pricing adjustments if the dialog is present
  }else{
  //checkboxInput.style.display = 'none'; 
    //checkboxInput.disabled = true; 
  }
});
</script>
